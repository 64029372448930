<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="13.5rem"
      :class="$style.form"
    >
      <el-form-item label="ИП">
        <el-checkbox v-model="form.isIndividualEntrepreneur" />
      </el-form-item>
      <el-form-item label="Название компании*" prop="name">
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item label="Юридический адрес" prop="legalAddress">
        <el-input v-model="form.legalAddress" />
      </el-form-item>
      <el-form-item label="ИНН/ЕГРПОУ/УНП/БИН*" prop="unp">
        <el-input v-model="form.unp" />
      </el-form-item>
      <div :class="$style.input">
        <el-button
          size="small"
          type="primary"
          :disabled="!form.unp"
          :class="$style.button"
          @click="updateByINN"
          >заполнить по ИНН
        </el-button>
        <div v-if="errorUpdateByINN" :class="$style.error">
          {{ errorUpdateByINN }}
        </div>
      </div>
      <el-form-item label="КПП" prop="kpp">
        <el-input v-model="form.kpp" />
      </el-form-item>
      <el-form-item label="КПП ОП" prop="kppop">
        <el-input v-model="form.kppop" />
      </el-form-item>
      <el-form-item label="ОГРН/ОГРНИП" prop="ogrn">
        <el-input v-model="form.ogrn" />
      </el-form-item>
      <el-form-item label="Расчетный счет*" prop="paymentAccount">
        <el-input v-model="form.paymentAccount" />
      </el-form-item>
      <el-form-item label="Наименование банка*" prop="bankName">
        <el-input v-model="form.bankName" />
      </el-form-item>
      <el-form-item label="БИК*" prop="bik">
        <el-input v-model="form.bik" />
      </el-form-item>
      <el-form-item label="Кор счет*" prop="corAccount">
        <el-input v-model="form.corAccount" />
      </el-form-item>
      <h5>Ответственное/контактное лицо</h5>
      <el-form-item label="ФИО*" prop="responsiblePerson">
        <el-input v-model="form.responsiblePerson" />
      </el-form-item>
      <el-form-item label="Телефон*" prop="phone">
        <el-input v-model="form.phone" />
      </el-form-item>
      <el-form-item label="Email*" prop="email">
        <el-input v-model="form.email" />
      </el-form-item>
      <el-form-item label="Прошел модерацию">
        <el-checkbox v-model="form.isVerified" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import notifications from '@/mixins/notifications'
import regexp from '@/helpers/regexp.js'

export default {
  mixins: [notifications],
  data() {
    return {
      errorUpdateByINN: '',
      form: {
        isIndividualEntrepreneur: false,
        name: '',
        legalAddress: '',
        unp: '',
        kpp: '',
        kppop: '',
        ogrn: '',
        paymentAccount: '',
        bankName: '',
        bik: '',
        corAccount: '',
        responsiblePerson: '',
        phone: '',
        email: '',
        isVerified: false
      },
      rules: {
        name: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        unp: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        paymentAccount: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        bankName: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        bik: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        corAccount: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        responsiblePerson: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.phone,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ],
        email: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur'
          },
          {
            pattern: regexp.email,
            message: 'Введен недопустимый символ',
            trigger: 'change'
          }
        ]
      }
    }
  },
  methods: {
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })

          const data = {
            ...this.form
          }

          const result =
            await delivery.AddwineCore.DealersActions.createClientRequesites(
              this.$route.params.id,
              data
            )
          loading.close()

          if (result.error) return

          this.showNotification('Реквизиты успешно созданы', 'success')
          this.$router.push(
            `/sellers/dealers/${this.$route.params.id}/client-requesites`
          )
        }
      })
    },
    async updateByINN() {
      const result =
        await delivery.AddwineCore.ClientRequesitesActions.getByDaData(
          this.form.unp
        )
      if (result.error) {
        this.errorUpdateByINN = result.error
      }
      if (!result.value.length) {
        this.errorUpdateByINN = 'К сожалению, ничего не найдено'
      } else {
        this.errorUpdateByINN = ''
        this.form.name = result.value[0].name
        this.form.kpp = result.value[0].kpp
        this.form.unp = result.value[0].unp
        this.form.ogrn = result.value[0].ogrn
        this.form.legalAddress = result.value[0].legalAddress
        this.form.isIndividualEntrepreneur =
          result.value[0].isIndividualEntrepreneur
      }
    }
  }
}
</script>

<style lang="scss" module>
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .input {
    .error {
      @include errorInscription;
      text-align: left;
      padding-top: 0.5rem;
    }
  }
}
</style>
